export default {
	removeNonNumericCharsOMD(phone) {
		return phone.replace(/\D/g, '')
	},

	// Method to format the phone number as xxx-xxx-xxxx
	newFormatPhoneNumberOMD(phone) {
		const cleaned = ('' + phone).replace(/\D/g, '') // Remove non-numeric characters
		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

		if (match) {
			return `${match[2]}-${match[3]}-${match[4]}`
		}

		return phone // If it doesn't match the format, return the original
	},
}
